import Vue from 'vue';
import Vuex from 'vuex';
import i18n from '@/i18n';
import { buildMeta, setMeta } from '../router/meta';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        app: {
            meta: {
                title: '',
                titleDefault: `${process.env.VUE_APP_NAME}`,
                titleSeparator: ' - ',
                description: '',
                descriptionDefault: '',
                image: '',
                imageDefault: `https://${process.env.VUE_APP_URL}/img/icons/android-chrome-512x512.png`,
                keywords: '',
                keywordsDefault: '',
                url: '',
                schemas: [],
            },
            cdn_path: `${process.env.VUE_APP_CDN_URL}`,
        },
        data: {},
        videoPlayer: {},
    },
    getters: {
        meta: (state, getters) => {
            const data = state.app.meta;
            return buildMeta(data, { lang: i18n.locale, languages: getters.languages });
        },

        data: (state) => state.data,
        languages: (state) => state.app.languages,
        videoPlayer: (state) => state.videoPlayer,
    },
    mutations: {
        setData(state, data) {
            state.data = data;
        },

        setOverlayVideo(state, { type, id }) {
            state.videoPlayer = { type, id };
        },

        setMeta(state, data) {
            state.app.meta = setMeta({
                ...data,
                statedata: state.app.meta,
            });
            return true;
        },
    },
    actions: {
        get({ commit }, page) {
            let params = {};
            if (typeof page === 'object') {
                params = page.params;
                page = page.page;
            }
            return new Promise((resolve, reject) => {
                Vue.prototype.$http
                    .get(`${page}`, {
                        params,
                    })
                    .then((r) => {
                        if (r.data && r.data.status === 'success') {
                            if (page === 'items/foundation_texts/1') {
                                commit('setData', r.data.results);
                            }
                            resolve(r.data.results);
                        } else {
                            reject();
                        }
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
});
