const createRoutesForNavigation = (routes) => {
    const returnRoute = (cur, key) => {
        if (cur.meta && cur.meta[key]) {
            if (typeof cur.meta[key] === 'string') {
                return {
                    name: cur.meta.header,
                    route: cur.name,
                };
            }
            return {
                route: cur.name,
                ...cur.meta[key],
            };
        }
        return undefined;
    };

    const addToGroup = (data, key, value) => {
        if (!data[key]) {
            data[key] = [];
        }
        data[key].push(value);

        return data;
    };

    return routes.reduce(
        (acc, cur) => {
            const headerRoute = returnRoute(cur, 'header');
            const footerRoute = returnRoute(cur, 'footer');
            if (headerRoute) {
                acc.header.push(headerRoute);
            }
            if (footerRoute) {
                addToGroup(acc.footer, footerRoute.group || 'default', footerRoute);
            }
            return acc;
        },
        { header: [], footer: {} },
    );
};

export default createRoutesForNavigation;
