import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import routes from '@/router/routes';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes.routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return { selector: to.hash };
        }
        if (savedPosition) {
            return savedPosition;
        }
        return { x: 0, y: 0 };
    },
});

router.beforeEach(async (to, from, next) => {
    if (!to.matched.length) {
        next({ path: '/404', params: { error: to.path } });
    } else {
        store.commit({ type: 'setMeta', from: 'router', data: to });
        next();
    }
});

router.onError((e) => {
    console.log('on error');
    router.replace({ name: '404', params: { error: e } }).catch(() => {});
});

export default router;
