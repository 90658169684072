import Vue from 'vue';

// Plugins
import axios from 'axios';
import VueLazyload from 'vue-lazyload';
import VueMeta from 'vue-meta';
import { strings } from 'p5-helpers';
import VueCurrencyFilter from 'vue-currency-filter';
import VTooltip from 'v-tooltip';
import webdata from './services/helpers/mixin.webdata';

// Global Components
import Actions from './components/ui/Actions.vue';
import Button from './components/ui/Button.vue';
import IntroText from './components/ui/IntroText.vue';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import i18n from './i18n';

global.router = router;

// Setup axios
const instance = axios.create({
    baseURL: `https://${process.env.VUE_APP_API_URL}`,
    headers: {
        Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
        namespace: `${process.env.VUE_APP_API_NAMESPACE}`,
    },
    timeout: 15000,
});
Vue.prototype.$http = instance;

Vue.use(VueLazyload, {
    lazyComponent: true,
});
Vue.mixin(webdata);
Vue.filter('nl2br', strings.nl2br);
Vue.filter('titleContrast', (val) => {
    if (typeof val === 'string') {
        const parts = val.split('==');
        if (parts.length < 2) { return val; }
        return `${parts[0]}<span class="color-accent">${parts[1]}</span>${parts[2]}`;
    }
    return val;
});
Vue.use(VueMeta, {
    keyName: 'meta',
    refreshOnceOnNavigation: true,
});
Vue.use(VueCurrencyFilter, {
    symbol: '€',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true,
    avoidEmptyDecimals: '-',
});
Vue.use(VTooltip);

Vue.component('Actions', Actions);
Vue.component('Button', Button);
Vue.component('IntroText', IntroText);

Vue.config.productionTip = false;
Vue.config.ignoredElements = [];

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
