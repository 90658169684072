<template>
    <nav>
        <div
            class="c w-xxl flex align-start pt-r justify-between m-down:flex-wrap l-up:pt-l"
        >
            <router-link to="/" class="navigation__logo mr-r size-0">
                The Good Roll Foundation
                <div>
                    <img src="/img/logo_good_roll_foundation.svg" alt="The Good Roll Foundation Logo" title="The Good Roll Foundation" />
                </div>
            </router-link>

            <button
                type="button"
                class="navigation__hamburger hover:color-accent l-up:hide -ghost"
                :title="$t('general.menu')"
                @click="showNavigation = !showNavigation"
            >
                <MenuIcon v-if="!showNavigation" :size="28" />
                <CloseIcon v-else :size="28" />
            </button>

            <div
                class="flex navigation__dropdown m-down:align-center m-down:w-12 m-down:flex-col-reverse l-up:align-end l-up:flex-col"
                :class="showNavigation ? 'm-down:mb-xxl': 'm-down:hide'"
            >
                <div class="navigation__miniheader size-7 flex text-uppercase align-center weight-b">
                    <span class="m-down:hide mr-s font-title">
                        {{ data.slogan }}
                    </span>
                    <template v-for="link in ['Facebook', 'Twitter', 'Instagram', 'Youtube', 'Linkedin']">
                        <a
                            v-if="data[link.toLowerCase() + '_url']"
                            :href="data[link.toLowerCase() + '_url']" :title="'The Good Roll Foundation - ' + link" :key="link" class="pl-s">
                            <component :is="link + 'Icon'"  :size="18" />
                        </a>
                    </template>
                </div>

                <ul
                    id="nav"
                    class="navigation__links flex align-center weight-b font-title m-down:flex-wrap m-down:justify-center m-down:py-xl l-up:mt-r"
                >
                    <li
                        v-for="page in pages"
                        :key="page.name"
                        :class="page.class"
                    >
                        <router-link
                            :to="{ name: page.route }"
                            class="block px-r m-down:py-l"
                        >
                            {{ $t(page.name) }}
                        </router-link>
                    </li>
                    <li>
                        <Button
                            to="/donate"
                            class="ml-s -s bg-accent shadow-m text-uppercase"
                        >
                            {{ $t('donate.menu') }}
                        </Button>
                    </li>
                </ul>
            </div>

        </div>
    </nav>
</template>

<script>
import MenuIcon from 'vue-material-design-icons/Menu.vue';
import CloseIcon from 'vue-material-design-icons/Close.vue';
import FacebookIcon from 'vue-material-design-icons/Facebook.vue';
import TwitterIcon from 'vue-material-design-icons/Twitter.vue';
import InstagramIcon from 'vue-material-design-icons/Instagram.vue';
import YoutubeIcon from 'vue-material-design-icons/Youtube.vue';
import LinkedinIcon from 'vue-material-design-icons/Linkedin.vue';

export default {
    name: 'Navigation',
    components: {
        MenuIcon,
        CloseIcon,
        FacebookIcon,
        TwitterIcon,
        InstagramIcon,
        YoutubeIcon,
        LinkedinIcon,
    },
    props: {
        pages: Array,
    },
    data() {
        return {
            showNavigation: false,
        };
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },
    },
};
</script>

<style>
    nav {
        --logo-width: 8.8rem;
    }
    @media (min-width: 62em) {
        nav {
            --logo-width: 10.8rem;
        }
    }
    @media (max-width: 62em) {
        .navigation__links { width: 100%; }
        .navigation__links li {
            flex: 0 0 50%;
            text-align: center;
        }
    }
    .navigation__logo {
        display: block;
        width: var(--logo-width);
        height: var(--logo-width);
    }
</style>
