<template>
    <div>
        <h3 v-if="title" class="text-center mb-l">{{ title }}</h3>
        <div class="logoslist flex flex-wrap justify-center align-center columns">
            <div
                v-for="item in items"
                :key="item.id"
                class="w-4 m-up:w-3 l-up:w-2 xl:w-2"
            >
                <v-popover v-bind="{ trigger: 'hover', delay: 100 }">
                    <slot name="item" :item="item">
                        <img
                            v-lazy="cdnurl(item.image)"
                            :alt="item.name"
                            :class="{ 'cursor-pointer': item.text_en }"
                            class="w-12 object-contain"
                        />
                    </slot>
                    <template v-slot:popover v-if="item.text_en">
                        <div class="tooltip--content">
                            <IntroText
                                :title="item.title_en"
                                :text="item.text_en"
                                titleClass="size-3"
                                :actions="item.link ? [
                                    {
                                        title: $t('general.readmore'),
                                        href: item.link,
                                        class: 'bg-accent-light color-primary',
                                    }
                                ] : []"
                            />
                        </div>
                    </template>
                </v-popover>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Logos',
    props: {
        title: String,
        list: Array,
        max: Number,
        needsImage: Boolean,
    },
    computed: {
        items() {
            let items = this.list;
            if (this.needsImage) {
                items = items.filter((it) => it.image);
            }
            items.sort(() => 0.5 - Math.random());
            if (this.max && items.length > this.max) {
                return items.slice(0, this.max);
            }
            return items;
        },
    },
};
</script>

<style scoped>
img {
    max-height: 11rem;
}
.columns {
    --column-gap: var(--size-l);
}
</style>