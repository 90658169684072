<template>
    <div id="app" class="flex flex-col align-stretch">
        <main>
            <router-view>
                <template v-slot:navigation>
                    <Navigation :pages="pages.header" />
                </template>
            </router-view>
        </main>
        <div v-if="$route.name !== 'About'" class="c w-xxl">
            <Logos
                class="my-xl"
                v-if="data && data.foundation_partners"
                :title="$t('general.partners')"
                :list="data.foundation_partners"
                :max="6"
                :needsImage="true"
            />
        </div>
        <Footer :pages="pages.footer" />
        <VideoPlayer v-bind="overlayVideo" />
    </div>
</template>

<script>
import Footer from './components/Footer.vue';
import Logos from './components/ui/Logos.vue';
import Navigation from './components/Navigation.vue';
import VideoPlayer from './components/ui/VideoPlayer.vue';
import createRoutesForNavigation from './router/navigationRoutes';

export default {
    name: 'App',
    components: {
        Navigation,
        VideoPlayer,
        Logos,
        Footer,
    },
    created() {
        if (window.PRERENDER_INJECTED) {
            this.$store.commit('setData', window.PRERENDER_INJECTED.texts);
        } else {
            this.$store.dispatch('get', 'items/foundation_texts/1').then((r) => {
                const lang = this.$i18n.locale;
                this.$store.commit('setMeta', {
                    from: 'config',
                    data: {
                        descriptionDefault: r[`meta_description_${lang}`],
                        titleDefault: r[`meta_title_${lang}`],
                        keywordsDefault: (Array.isArray(r[`meta_keywords_${lang}`])
                            ? r[`meta_keywords_${lang}`]
                            : []
                        )
                            .map((it) => it.name)
                            .join(', '),
                    },
                    schemas: [
                        {
                            '@context': 'https://schema.org',
                            '@type': 'FundingAgency',
                            name: `${process.env.VUE_APP_NAME}`,
                            description: r[`meta_description_${lang}`],
                            address:
                                r.contact_address && r.contact_address.parts
                                    ? {
                                        '@type': 'PostalAddress',
                                        addressLocality: r.contact_address.parts.city,
                                        addressCountry: r.contact_address.parts.country,
                                        postalCode: r.contact_address.parts.zip,
                                        streetAddress: `${r.contact_address.parts.street} ${r.contact_address.parts.number}`,
                                    }
                                    : {},
                            email: r.contact_email,
                            logo: `https://${process.env.VUE_APP_URL}/img/logo_good_roll_foundation.png`,
                            slogan: r[`intro_title_${lang}`],
                            image: this.cdnurl(r.intro_image),
                            url: `https://${process.env.VUE_APP_URL}`,
                        },
                    ],
                });
            });
        }
    },

    computed: {
        data() {
            return this.$store.getters.data;
        },

        pages() {
            const routes = this.$router.options.routes;
            return createRoutesForNavigation(routes);
        },

        overlayVideo() {
            return this.$store.getters.videoPlayer;
        },
    },

    meta() {
        return this.$store.getters.meta;
    },
};
</script>

<style>
@import '~p5-spark-css/dist/forms.css';
@import '~p5-spark-css/dist/vue.css';
@import '~p5-spark-css/dist/base.css';
@import 'assets/css/application.css';
@import 'assets/css/tooltip.css';
</style>
