const prerenderSettings = {
    remoteData: {
        texts: '/items/foundation_texts/1',
    },
    redirects: {
        default: ['/*   /index.html 200'],
    },
    sitemap: true,
};

const routes = [
    {
        path: '',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
        meta: {
            prerender: true,
        },
    },
    {
        path: '/sanitation',
        name: 'Sanitation',
        component: () => import('@/views/Article.vue'),
        meta: {
            article: {
                endpoint: 'items/foundation_sanitation/1',
                schema: (data, context) => [
                    {
                        '@context': 'https://schema.org',
                        '@type': 'Article',
                        headline: data.meta_title_en,
                        abstract: data.meta_description_en,
                        articleBody: data.config_en.blocks
                            .map((block) =>
                                block.type === 'paragraph'
                                    ? block.data.text.replace(/(<([^>]+)>)/gi, '')
                                    : ''
                            )
                            .join(''),
                        author: {
                            '@type': 'Organization',
                            name: `${process.env.VUE_APP_NAME}`,
                            logo: `https://${process.env.VUE_APP_URL}/img/logo_good_roll_foundation.png`,
                        },
                        publisher: {
                            '@type': 'Organization',
                            name: `${process.env.VUE_APP_NAME}`,
                            logo: {
                                '@type': 'ImageObject',
                                logo: `https://${process.env.VUE_APP_URL}/img/logo_good_roll_foundation.png`,
                            },
                        },
                        datePublished: new Date(data.created.timestamp * 1000).toISOString(),
                        dateModified: new Date(
                            (data.edited && data.edited.timestamp
                                ? data.edited.timestamp
                                : data.created.timestamp) * 1000
                        ).toISOString(),
                        mainEntityOfPage: window.location.href,
                    },
                ],
            },
            header: 'sanitation.menu',
            footer: {
                group: 'general.menu',
                name: 'sanitation.menu',
            },
            prerender: {
                url: 'items/foundation_sanitation/1',
            },
        },
    },
    {
        path: '/impact',
        name: 'Impact',
        component: () => import('@/views/Impact.vue'),
        meta: {
            article: {
                endpoint: 'items/foundation_impact/1',
                schema: (data, context) => [
                    {
                        '@context': 'https://schema.org',
                        '@type': 'Article',
                        headline: data.meta_title_en,
                        abstract: data.meta_description_en,
                        articleBody: data.config_en.blocks
                            .map((block) =>
                                block.type === 'paragraph'
                                    ? block.data.text.replace(/(<([^>]+)>)/gi, '')
                                    : ''
                            )
                            .join(''),
                        author: {
                            '@type': 'Organization',
                            name: `${process.env.VUE_APP_NAME}`,
                            logo: `https://${process.env.VUE_APP_URL}/img/logo_good_roll_foundation.png`,
                        },
                        publisher: {
                            '@type': 'Organization',
                            name: `${process.env.VUE_APP_NAME}`,
                            logo: {
                                '@type': 'ImageObject',
                                logo: `https://${process.env.VUE_APP_URL}/img/logo_good_roll_foundation.png`,
                            },
                        },
                        datePublished: new Date(data.created.timestamp * 1000).toISOString(),
                        dateModified: new Date(
                            (data.edited && data.edited.timestamp
                                ? data.edited.timestamp
                                : data.created.timestamp) * 1000
                        ).toISOString(),
                        mainEntityOfPage: window.location.href,
                    },
                ],
            },
            header: 'impact.menu',
            footer: {
                group: 'general.menu',
                name: 'impact.menu',
            },
            prerender: {
                url: 'items/foundation_impact/1',
            },
        },
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('@/views/About.vue'),
        meta: {
            article: {
                endpoint: 'items/foundation_about/1',
                schema: (data, context) => [
                    {
                        '@context': 'https://schema.org',
                        '@type': 'Article',
                        headline: data.meta_title_en,
                        abstract: data.meta_description_en,
                        articleBody: data.config_en.blocks
                            .map((block) =>
                                block.type === 'paragraph'
                                    ? block.data.text.replace(/(<([^>]+)>)/gi, '')
                                    : ''
                            )
                            .join(''),
                        author: {
                            '@type': 'Organization',
                            name: `${process.env.VUE_APP_NAME}`,
                            logo: `https://${process.env.VUE_APP_URL}/img/logo_good_roll_foundation.png`,
                        },
                        publisher: {
                            '@type': 'Organization',
                            name: `${process.env.VUE_APP_NAME}`,
                            logo: {
                                '@type': 'ImageObject',
                                logo: `https://${process.env.VUE_APP_URL}/img/logo_good_roll_foundation.png`,
                            },
                        },
                        datePublished: new Date(data.created.timestamp * 1000).toISOString(),
                        dateModified: new Date(
                            (data.edited && data.edited.timestamp
                                ? data.edited.timestamp
                                : data.created.timestamp) * 1000
                        ).toISOString(),
                        mainEntityOfPage: window.location.href,
                    },
                ],
            },
            header: 'about.menu',
            footer: {
                group: 'general.menu',
                name: 'about.menu',
            },
            prerender: {
                url: 'items/foundation_about/1',
            },
        },
    },
    {
        path: '/toiletfestival',
        name: 'toiletfestival',
        component: () => import('@/views/Toiletday.vue'),
        meta: {
            article: {
                endpoint: 'items/foundation_toiletday/1',
                schema: (data, context) => [
                    {
                        '@context': 'https://schema.org',
                        '@type': 'Article',
                        headline: data.meta_title_en,
                        abstract: data.meta_description_en,
                        articleBody: data.config_en.blocks
                            .map((block) =>
                                block.type === 'paragraph'
                                    ? block.data.text.replace(/(<([^>]+)>)/gi, '')
                                    : ''
                            )
                            .join(''),
                        author: {
                            '@type': 'Organization',
                            name: `${process.env.VUE_APP_NAME}`,
                            logo: `https://${process.env.VUE_APP_URL}/img/logo_good_roll_foundation.png`,
                        },
                        publisher: {
                            '@type': 'Organization',
                            name: `${process.env.VUE_APP_NAME}`,
                            logo: {
                                '@type': 'ImageObject',
                                logo: `https://${process.env.VUE_APP_URL}/img/logo_good_roll_foundation.png`,
                            },
                        },
                        datePublished: new Date(data.created.timestamp * 1000).toISOString(),
                        dateModified: new Date(
                            (data.edited && data.edited.timestamp
                                ? data.edited.timestamp
                                : data.created.timestamp) * 1000
                        ).toISOString(),
                        mainEntityOfPage: window.location.href,
                    },
                ],
            },
            header: 'toiletfestival.menu',
            prerender: {
                url: 'items/foundation_toiletday/1',
            },
        },
    },
    {
        path: '/projects/:cat/:slug?',
        name: 'ProjectsCat',
        component: () => import('@/views/Projects.vue'),
        props(route) {
            const props = { ...route.params };
            props.cat = +props.cat;
            return props;
        },
        meta: {
            schema: (data, context) => [
                {
                    '@context': 'https://schema.org',
                    '@type': 'ItemList',
                    name: 'Projects',
                    itemListElement: [
                        ...data.map((it, index) => ({
                            '@type': 'ListItem',
                            position: index + 1,
                            url: `/project/${it.id}/${context.kebabcase(it.name)}`,
                        })),
                    ],
                },
            ],
        },
    },
    {
        path: '/projects',
        name: 'Projects',
        component: () => import('@/views/Projects.vue'),
        meta: {
            header: 'projects.menu',
            footer: {
                group: 'general.menu',
                name: 'projects.menu',
            },
            prerender: true,
        },
    },
    {
        path: '/project/:id/:slug?',
        name: 'Project',
        component: () => import('@/views/Article.vue'),
        meta: {
            article: {
                endpoint: 'items/foundation_projects/[id]',
                schema: (data, context) => [
                    {
                        '@context': 'https://schema.org',
                        '@type': 'Project',
                        name: data.name,
                        address:
                            data.location && data.location.parts
                                ? {
                                    '@type': 'PostalAddress',
                                    addressLocality: data.location.parts.city,
                                    postalCode: data.location.parts.zip,
                                    addressCountry: data.location.parts.country,
                                    streetAddress: `${data.location.parts.street} ${data.location.parts.number}`,
                                }
                                : {},
                        description: data[`subtitle_en`].replace(/(<([^>]+)>)/gi, ''),
                        image: context.cdnurl(data.image1),
                        funder: process.env.VUE_APP_NAME,
                    },
                ],
            },
            prerender: {
                url: 'items/foundation_projects/',
                path: '/project/[id]/[name|kebabcase]',
            },
        },
    },
    {
        path: '/contact',
        name: 'Contact',
        component: () => import('@/views/Contact.vue'),
        meta: {
            header: 'contact.menu',
            footer: {
                name: 'contact.menu',
                group: 'footer.participate',
            },
            prerender: true,
        },
    },
    {
        path: '/donate',
        name: 'Donate',
        component: () => import('@/views/Donate.vue'),
        meta: {
            footer: {
                name: 'donate.menu',
                group: 'footer.participate',
            },
            prerender: true,
        },
    },
    {
        path: '/donate/success',
        name: 'DonateSuccess',
        component: () => import('@/views/State.vue'),
    },
    {
        path: '/donate/error',
        name: 'DonateError',
        component: () => import('@/views/State.vue'),
    },
    {
        path: '/404',
        name: 'Error404',
        component: () => import('@/views/State.vue'),
    },
];

exports.routes = routes;
exports.prerenderSettings = prerenderSettings;
