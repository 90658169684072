import get from 'lodash.get';

const data = {
    methods: {
        webdata(path, translate) {
            let lang;
            if (translate) {
                lang = this.$store.getters['Language/current'];
                path += `_${lang}`;
            }
            return get(this.$store.state.data, path, '');
        },

        inlinedata(object, path, lang) {
            if (lang) {
                path += `_${lang}`;
            }
            return get(object, path, '');
        },

        cdnurl(path) {
            if (!path) {
                return undefined;
            }
            return `${this.$store.state.app.cdn_path}/${path}`;
        },

        videourl(id, type = 'youtube') {
            switch (type) {
            case 'youtube':
                return `https://i.ytimg.com/vi/${id}/0.jpg`;

            default:
            }
            return '';
        },
    },
};

export default data;
