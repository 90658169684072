<template>
    <WaveUpBG>
        <footer class="c w-xxl pb-l size-6">
            <router-link to="/" class="navigation__logo mb-r size-0">
                The Good Roll Foundation
                <div>
                    <img src="/img/logo_good_roll_foundation.svg" alt="The Good Roll Foundation Logo" title="The Good Roll Foundation" />
                </div>
            </router-link>

            <div class="flex flex-wrap relative">
                <div class="w-12 m-up:w-6 l-up:w-3 xl:w-2 pr-r mb-r">
                    <strong>The Good Roll Foundation</strong><br />
                    <div v-html="$options.filters.nl2br(data.contact_details_footer_en)" />
                    <a :href="'mailto:' + data.contact_email">{{ data.contact_email }}</a>
                </div>

                <div
                    class="w-12 m-up:w-6 l-up:w-3 xl:w-2 pr-r mb-r"
                    v-for="(group, name) in pages"
                    :key="'footer' + name"
                >
                    <strong>{{ $t(name) }}</strong><br />
                    <router-link
                        v-for="page in group"
                        :key="page.name"
                        :class="page.class"
                        :to="{ name: page.route }"
                        class="block"
                    >
                        {{ $t(page.name) }}
                    </router-link>
                </div>
                <a href="https://projectfive.nl" rel="nofollow noreferrer" class="hover:opacity-50 flex align-center size-6 color-secondary footer__siteby">
                    Site by
                    <img src="/img/logo_projectfive.svg" alt="Project Five Logo" title="Project Five" class="w-24 h-24 ml-s" />
                </a>
            </div>
        </footer>
    </WaveUpBG>
</template>

<script>
import WaveUpBG from '@/components/bgs/WaveUp.vue';

export default {
    name: 'Footer',
    components: {
        WaveUpBG,
    },
    props: {
        pages: Object,
    },
    computed: {
        data() {
            return this.$store.getters.data;
        },
    },
};
</script>

<style>
footer {
    --logo-width: 7rem;
}
.footer__siteby {
    position: absolute;
    bottom: 0; right: 0;
}
</style>
