<template>
    <div v-if="type && id" class="overlay flex align-center justify-center" @click.self="closePlayer">
        <div class="videoplayer">
            <lazy-component tag="div" class="block--embed block--embed-youtube" v-if="type === 'youtube'">
                <iframe :src="'https://www.youtube-nocookie.com/embed/' + id" frameborder="0" allowfullscreen></iframe>
            </lazy-component>
            <button type="button" @click="closePlayer" class="color-accent">
                <CloseIcon :size="24" class="mr-s" />
                {{ $t('general.close') }}
            </button>
        </div>
    </div>
</template>

<script>
import CloseIcon from 'vue-material-design-icons/Close.vue';

export default {
    name: 'Videoplayer',
    components: {
        CloseIcon,
    },
    props: {
        type: String,
        id: String,
    },
    methods: {
        closePlayer() {
            this.$store.commit('setOverlayVideo', { type: undefined, id: undefined });
        },
    },
};
</script>

<style>
.overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
}
.videoplayer {
    width: 90%;
}
.videoplayer > button {
    position: absolute;
    z-index: 100;
    right: var(--size-l);
    bottom: var(--size-l);
}

.block--embed-youtube {
    border-radius: var(--radius-m);
    overflow: hidden;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    position: relative;
    display: block;
    margin: var(--size-xl) 0;
}
.block--embed-youtube iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>
